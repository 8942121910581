/// <summary>
/// Author: -
/// </summary>
import axios from 'axios';
import { ApiKey, AlertTypes } from './Constant.js';
import * as AppActions from '../redux/AppAction';
import * as AuthActions from '../redux/AuthAction';
import Store from '../ApplicationStore';
import { showMessage } from './Util';

const _HTTP_CODE_BAD_REQUEST = 400;
const _HTTP_CODE_UNAUTHORIZED = 401;
const _HTTP_CODE_FORBIDDEN = 403;
const _HTTP_CODE_INTERNAL_SERVER_ERROR = 500;

/// <summary>
/// Author: -
/// </summary> 
async function interceptRequest(requestConfig) {
    if (requestConfig.headers.skipLoading === undefined ||
        requestConfig.headers.skipLoading === false) {
        Store.dispatch(AppActions.setBusy());
    }
    return requestConfig;
}

/// <summary>
/// Author: -
/// </summary>
function interceptResponse(response) {
    Store.dispatch(AppActions.setPerformApiCall(response.data[ApiKey._API_SUCCESS_KEY], response.config.url));

    if (!response.config?.headers?.skipLoading) {
        Store.dispatch(AppActions.setIdle());
    }

    return response.data;
}

/// <summary>
/// Author: -
/// </summary>
function interceptError(error) {
    var returnData = {};

    Store.dispatch(AppActions.setIdle());

    if (error.response.status == _HTTP_CODE_UNAUTHORIZED || error.response.status == _HTTP_CODE_FORBIDDEN) {
        if (error.response.status == _HTTP_CODE_UNAUTHORIZED) {
            Store.dispatch(AuthActions.performLogout());
        }

        showMessage({
            type: AlertTypes._ERROR,
            content: error.response.status == _HTTP_CODE_UNAUTHORIZED ? "USER_UNAUTHORIZED" : "PERMISSION_DENIED",
            onConfirm: () => {
                if (error.response.status == _HTTP_CODE_FORBIDDEN) {
                    // Store.dispatch({ type: AuthActions._ACTION_FORBIDDEN, value: true });
                }
            }
        });

        returnData = {
            "success": false,
            "message": error.response.status == _HTTP_CODE_UNAUTHORIZED ? "USER_UNAUTHORIZED" : "PERMISSION_DENIED"
        }
    }
    else if (error.response.status == _HTTP_CODE_BAD_REQUEST || error.response.status == _HTTP_CODE_INTERNAL_SERVER_ERROR) {
        returnData = {
            "success": false,
            "message": "INTERNAL_SERVER_ERROR"
        }
    }

    return returnData;
}

const ApiEngine = axios.create({
    headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
    },
});

ApiEngine.interceptors.request.use(
    config => interceptRequest(config),
    error => interceptError(error),
);

ApiEngine.interceptors.response.use(
    response => interceptResponse(response),
    error => interceptError(error),
);

export default ApiEngine;