import React, { lazy, Suspense } from 'react';
import { Redirect } from 'react-router';
import { WebUrl, AccessRight, Role, _RESTRICTED_SUB_ACCOUNT_7_POOLS } from "../util/Constant";
import LoadingOverlay from 'react-loading-overlay';
import i18n from '../localization/i18n';
import HashLoader from "react-spinners/HashLoader";

/// <summary>
/// Author : CK
/// </summary>
export const load = (Component: any) => (props: any) => (
    <Suspense fallback={<div className="loader-wrapper"><HashLoader size={25} color={process.env.REACT_APP_LOADER_COLOR} /></div>}>
        <Component {...props} />
    </Suspense>
)

const Login = load(lazy(() => import('./../pages/Login')));
const TermsAndConditions = load(lazy(() => import('./../pages/TermsAndConditions')));
const Dashboard = load(lazy(() => import('./../pages/Dashboard')));
const Help = load(lazy(() => import('./../pages/Help')));
const ManageAccount = load(lazy(() => import('./../pages/account/ManageAccount')));
const AccountDetail = load(lazy(() => import('./../pages/account/AccountDetail')));
const ManageFightLimit = load(lazy(() => import('./../pages/account/ManageFightLimit')));
const ReserveNamePrefix = load(lazy(() => import('./../pages/account/ReserveNamePrefix')));
const CreateApiAccount = load(lazy(() => import('./../pages/account/CreateApiAccount')));
const SubAccountDetail = load(lazy(() => import('./../pages/account/SubAccountDetail')));

// Admin Settings
const PoolSchedule = load(lazy(() => import('./../pages/admin/PoolSchedule')));
const ManagePackage = load(lazy(() => import('./../pages/package/ManagePackage')));
const PackageDetail = load(lazy(() => import('./../pages/package/PackageDetail')));
const Results = load(lazy(() => import('./../pages/admin/Results')));
const CutoffTime = load(lazy(() => import('./../pages/admin/CutoffTime')));
const ManageRoles = load(lazy(() => import('../pages/roles/ManageRoles')));
const Announcement = load(lazy(() => import('../pages/admin/Announcement')));
const SystemSetting = load(lazy(() => import('../pages/admin/SystemSetting')));
const Currency = load(lazy(() => import('../pages/admin/Currency')));
const RedNumber = load(lazy(() => import('../pages/admin/RedNumber')));
const ManageMasterTemplate = load(lazy(() => import('../pages/admin/ManageMasterTemplate')));
const WebOrder = load(lazy(() => import('../pages/betting/WebOrder')));
const ApiLog = load(lazy(() => import('../pages/admin/ManageApiLog')));
const ApiRequestLog = load(lazy(() => import('../pages/admin/ManageApiRequestLog')));
const AdminTool = load(lazy(() => import('../pages/admin/AdminTool')));

const NumberMeaning = load(lazy(() => import('../pages/tip/NumberMeaning')));

const ExpressBetting = load(lazy(() => import('../pages/order/ExpressBetting')));
const CloneAccount = load(lazy(() => import('./../pages/account/CloneAccount')));

const Payment = load(lazy(() => import('./../pages/payment/Payment')));
const DrawPayment = load(lazy(() => import('./../pages/payment/DrawPayment')));
const AutoTicket = load(lazy(() => import('../pages/betting/AutoTicket')));

// report
const SalesSummaryReport = load(lazy(() => import('./../pages/report/SalesSummaryReport')));
const MapReport = load(lazy(() => import('./../pages/report/MapReport')));
const SalesStatisticReport = load(lazy(() => import('./../pages/report/SalesStatisticReport')));
const StakeHolderReport = load(lazy(() => import('./../pages/report/StakeHolderReport')));
const WinLoss3SectionReport = load(lazy(() => import('./../pages/report/WinLoss3SectionReport')));
const PayoutStatisticReport = load(lazy(() => import('./../pages/report/PayoutStatisticReport')));
const Inbox = load(lazy(() => import('./../pages/receipt/Inbox')));
const PayoutReport = load(lazy(() => import('./../pages/report/PayoutReport')));
const PayoutTableReport = load(lazy(() => import('./../pages/report/PayoutTableReport')));
const WinLossReport = load(lazy(() => import('./../pages/report/WinLossReport')));
const WinningReport = load(lazy(() => import('./../pages/report/WinningReport')));
const WinLossDetail = load(lazy(() => import('../components/custom/WinLossDetail')));
const JackpotDetail = load(lazy(() => import('../components/custom/JackpotDetail')));
const SelfWinLossDetail = load(lazy(() => import('../components/custom/SelfWinLossDetail')));
const StrikeReport = load(lazy(() => import('./../pages/report/StrikeReport')));

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export const _LOGIN_ROUTE =
{
    path: WebUrl._URL_MAIN,
    exact: false,
    authRequired: false,
    component: () => <Redirect to={WebUrl._URL_LOGIN} />
}

export const _ROUTES = [
    {
        path: WebUrl._URL_LOGIN,
        exact: true,
        title: 'Login',
        authRequired: false,
        component: () => <Login />
    },
    {
        path: WebUrl._URL_TERMS_AND_CONDITIONS,
        exact: true,
        authRequired: true,
        title: 'Terms And Conditions',
        component: () => <TermsAndConditions />
    },
    {
        path: WebUrl._URL_MAIN,
        exact: true,
        authRequired: true,
        component: () => <Redirect to={WebUrl._URL_DASHBOARD} />
    },
    {
        path: WebUrl._URL_DASHBOARD,
        exact: true,
        authRequired: true,
        title: 'Dashboard',
        component: () => <Dashboard />
    },
    {
        path: WebUrl._URL_ACCOUNT_MANAGEMENT,
        exact: true,
        authRequired: true,
        title: 'Account Management',
        permission: { activity: AccessRight._DOWNLINE_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        component: () => <ManageAccount />
    },
    {
        path: WebUrl._URL_ACCOUNT_DETAIL,
        exact: true,
        authRequired: true,
        title: 'User Detail',
        permission: { activity: AccessRight._DOWNLINE_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION, AccessRight._CREATE_PERMISSION, AccessRight._EDIT_PERMISSION] },
        component: () => <AccountDetail />
    },
    {
        path: WebUrl._URL_MANAGE_PROFILE,
        exact: true,
        authRequired: true,
        title: 'Manage Profile',
        component: () => <AccountDetail />
    },
    {
        path: WebUrl._URL_SUB_ACCOUNT_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Sub Account Detail',
        permission: { activity: AccessRight._MAIN_ACCOUNT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION, AccessRight._CREATE_PERMISSION, AccessRight._EDIT_PERMISSION] },
        component: () => <SubAccountDetail />
    },
    {
        path: WebUrl._URL_COMPANY_MANAGEMENT,
        exact: true,
        authRequired: true,
        title: 'Company Detail',
        role: [Role._SUPER_ADMIN],
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION, AccessRight._CREATE_PERMISSION, AccessRight._EDIT_PERMISSION] },
        component: () => <AccountDetail />
    },
    {
        path: WebUrl._URL_MANAGE_PACKAGE,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._HOUSE, Role._MULTILEVEL, Role._SHAREHOLDER, Role._ADMIN, Role._SUPER_SENIOR, Role._SENIOR, Role._MASTER, Role._AGENT],
        title: 'Manage Package',
        component: () => <ManagePackage />
    },
    {
        path: WebUrl._URL_PACKAGE_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Package Detail',
        component: () => <PackageDetail />
    },
    {
        path: WebUrl._URL_POOL_SCHEDULE,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN],
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Pool Schedule',
        component: () => <PoolSchedule />
    },
    {
        path: WebUrl._URL_RESULT,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN, Role._COMPANY],
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Results',
        component: () => <Results />
    },
    {
        path: WebUrl._URL_CUTOFF_TIME,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN],
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: "Cutoff Time",
        component: () => <CutoffTime />
    },
    {
        path: WebUrl._URL_ROLES,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN],
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Roles Management',
        component: () => <ManageRoles />
    },
    {
        path: WebUrl._URL_FIGHT_LIMIT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._FIGHT_LIMIT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Manage Fight Limit',
        component: () => <ManageFightLimit />
    },
    {
        path: WebUrl._URL_ANNOUNCEMENT,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN],
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Announcement',
        component: () => <Announcement />
    },
    {
        path: WebUrl._URL_SYSTEM_SETTING,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN],
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'System Setting',
        component: () => <SystemSetting />
    },
    {
        path: WebUrl._URL_CURRENCY,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN],
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Currency',
        component: () => <Currency />
    },
    {
        path: WebUrl._URL_RED_NUMBER,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._RED_NUMBER_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Red Number',
        component: () => <RedNumber />
    },
    {
        path: WebUrl._URL_MANAGE_MASTER_TEMPLATE,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN],
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Master Template',
        component: () => <ManageMasterTemplate />
    },
    {
        path: WebUrl._URL_WEB_ORDER,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._BET_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Web Order',
        component: () => <WebOrder />
    },
    {
        path: WebUrl._URL_WEB_ORDER_TWO,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._BET_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Web Order (2)',
        component: () => <WebOrder isWebOrderTwo={true} />
    },
    {
        path: WebUrl._URL_NUMBER_MEANING,
        exact: true,
        authRequired: true,
        title: 'Number Meaning',
        component: () => <NumberMeaning />
    },
    {
        path: WebUrl._URL_EXPRESS_BETTING,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._BET_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Express Betting',
        component: () => <ExpressBetting />
    },
    {
        path: WebUrl._URL_RESERVE_NAME_PREFIX,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Reserve Name Prefix',
        component: () => <ReserveNamePrefix />
    },
    {
        path: WebUrl._URL_CREATE_API_ACCOUNT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Create Api Account',
        component: () => <CreateApiAccount />
    },
    {
        path: WebUrl._URL_CLONE_ACCOUNT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._COMPANY_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Clone User',
        component: () => <CloneAccount />
    },
    {
        path: WebUrl._URL_PAYMENT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._PAYMENT_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Payment',
        component: () => <Payment />
    },
    {
        path: WebUrl._URL_DRAW_PAYMENT,
        exact: true,
        authRequired: true,
        title: 'Draw Payment',
        permission: { activity: AccessRight._PAYMENT_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        role: [Role._HOUSE, Role._MULTILEVEL, Role._AGENT, Role._SUPER_SENIOR, Role._SENIOR, Role._MASTER, Role._COMPANY, Role._SHAREHOLDER],
        component: () => <DrawPayment />
    },
    {
        path: WebUrl._URL_SALES_SUMMARY_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._DAILY_SALES_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Sales Summary Report',
        component: () => <SalesSummaryReport />
    },
    {
        path: WebUrl._URL_MAP_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._MAP_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Map Report',
        component: () => <MapReport />
    },
    {
        path: WebUrl._URL_SALES_STATISTIC_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._GROUP_SALES_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Sales Statistic Report',
        component: () => <SalesStatisticReport />
    },
    {
        path: WebUrl._URL_PAYOUT_STATISTIC_REPORT,
        exact: true,
        authRequired: true,
        title: 'Payout Statistic Report',
        component: () => <PayoutStatisticReport />
    },
    {
        path: WebUrl._URL_INBOX,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._INBOX_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Inbox',
        component: () => <Inbox />
    },
    {
        path: WebUrl._URL_HELP,
        exact: true,
        authRequired: true,
        title: 'Help',
        component: () => <Help />
    },
    {
        path: WebUrl._URL_PAYOUT_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._PAYOUT_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Payout Report',
        component: () => <PayoutReport />
    },
    {
        path: WebUrl._URL_PAYOUT_TABLE_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._PAYOUT_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Payout Table Report',
        component: () => <PayoutTableReport />
    },
    {
        path: WebUrl._URL_STAKE_HOLDER_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._STAKE_HOLDER_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Stake Holder Report',
        component: () => <StakeHolderReport />
    },
    {
        path: WebUrl._URL_WIN_LOSS_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._PROFIT_LOSS_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Win Loss Report',
        component: () => <WinLossReport />
    },
    {
        path: WebUrl._URL_WIN_LOSS_3_SECTION_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._PROFIT_LOSS_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Win Loss Report',
        component: () => <WinLoss3SectionReport />
    },
    {
        path: WebUrl._URL_WINNING_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._WINNING_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Winning Report',
        component: () => <WinningReport />
    },
    {
        path: WebUrl._URL_WIN_LOSS_DETAIL,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._PROFIT_LOSS_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Win Loss Detail',
        component: () => <WinLossDetail />
    },
    {
        path: WebUrl._URL_JACKPOT_DETAIL,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._PROFIT_LOSS_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Jackpot Detail',
        component: () => <JackpotDetail />
    },
    {
        path: WebUrl._URL_API_LOG,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN],
        title: 'Api Log',
        component: () => <ApiLog />
    },
    {
        path: WebUrl._URL_API_REQUEST_LOG,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN],
        title: 'Api Request Log',
        component: () => <ApiRequestLog />
    },
    {
        path: WebUrl._URL_ADMIN_TOOL,
        exact: true,
        authRequired: true,
        role: [Role._SUPER_ADMIN],
        title: 'Admin Tool',
        component: () => <AdminTool />
    },
    {
        path: WebUrl._URL_SELF_WIN_LOSS_DETAIL,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._PROFIT_LOSS_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Self Win Loss Detail',
        component: () => <SelfWinLossDetail />
    },
    {
        path: WebUrl._URL_STRIKE_REPORT,
        exact: true,
        authRequired: true,
        permission: { activity: AccessRight._WINNING_REPORT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] },
        title: 'Strike Report',
        component: () => <StrikeReport />
    },
    {
        path: WebUrl._URL_AUTO_TICKET,
        exact: true,
        authRequired: true,
        title: 'Auto Ticket',
        component: () => <AutoTicket />
    },
];

export default _ROUTES;