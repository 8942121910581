import React from 'react';
import { PageSettings } from './config/page-settings.js';
import { Redirect } from 'react-router'
import Header from './components/header/header.jsx';
import Content from './components/content/content.jsx';
import Footer from './components/footer/footer.jsx';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, SessionKey, LocalStorageKey, _LOCAL_STORAGE_DISPATCH } from "./util/Constant";
import { isObjectEmpty, stringIsNullOrEmpty } from "./util/Util";
import { disposeMessage } from './redux/AppAction.js';
import { updateLoginUser, resetLogin, setPendingTNC } from "./redux/AuthAction.js";
import { fetch } from 'whatwg-fetch'
import Notiflix from "notiflix";
import { Notify, Report, Confirm, Loading, Block } from "notiflix";
import { createStorageListener } from './util/Util';
import store from "./ApplicationStore.js";
import { Helmet } from "react-helmet";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.handleSetPageContent = (value) => {
            this.setState(state => ({
                pageContent: value
            }));
        }
        this.handleSetPageContentClass = (value) => {
            this.setState(state => ({
                pageContentClass: value
            }));
        }
        this.handleSetPageContentFullHeight = (value) => {
            this.setState(state => ({
                pageContentFullHeight: value
            }));
        }
        this.handleSetPageContentFullWidth = (value) => {
            this.setState(state => ({
                pageContentFullWidth: value
            }));
        }
        this.handleSetPageContentInverseMode = (value) => {
            this.setState(state => ({
                pageContentInverseMode: value
            }));
        }

        this.handleSetPageHeader = (value) => {
            this.setState(state => ({
                pageHeader: value
            }));
        }
        this.handleSetPageHeaderMegaMenu = (value) => {
            this.setState(state => ({
                pageHeaderMegaMenu: value
            }));
        }
        this.handleSetPageHeaderLanguageBar = (value) => {
            this.setState(state => ({
                pageHeaderLanguageBar: value
            }));
        }

        this.handleSetPageFooter = (value) => {
            this.setState(state => ({
                pageFooter: value
            }));
        }
        this.handleSetPageTopMenu = (value) => {
            this.setState(state => ({
                pageTopMenu: value
            }));
        }
        this.toggleMobileTopMenu = (e) => {
            e.preventDefault();
            this.setState(state => ({
                pageMobileTopMenu: !this.state.pageMobileTopMenu
            }));
        }
        this.handleSetPageBoxedLayout = (value) => {
            if (value === true) {
                document.body.classList.add('boxed-layout');
            } else {
                document.body.classList.remove('boxed-layout');
            }
        }
        this.handleSetBodyWhiteBg = (value) => {
            if (value === true) {
                document.body.classList.add('bg-white');
            } else {
                document.body.classList.remove('bg-white');
            }
        }

        this.state = {
            pageHeader: true,
            pageheaderMegaMenu: false,
            pageHeaderLanguageBar: false,
            hasScroll: false,
            handleSetPageHeader: this.handleSetPageHeader,
            handleSetPageHeaderLanguageBar: this.handleSetPageHeaderLanguageBar,
            handleSetPageHeaderMegaMenu: this.handleSetPageHeaderMegaMenu,

            pageContent: true,
            pageContentClass: '',
            pageContentFullHeight: false,
            pageContentFullWidth: false,
            pageContentInverseMode: false,
            handleSetPageContent: this.handleSetPageContent,
            handleSetPageContentClass: this.handleSetPageContentClass,
            handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
            handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
            handleSetPageContentInverseMode: this.handleSetPageContentInverseMode,

            pageFooter: false,
            handleSetPageFooter: this.handleSetPageFooter,
            error: null,
            errorInfo: null,

            pageTopMenu: false,
            pageMobileTopMenu: false,
            toggleMobileTopMenu: this.toggleMobileTopMenu,
            handleSetPageTopMenu: this.handleSetPageTopMenu,

            handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
            handleSetPageBoxedLayout: this.handleSetPageBoxedLayout
        };
    }

    /// <summary>
    /// Author: CK
    /// Edited: Wind - Added Notiflix
    /// </summary>
    componentDidMount() {
        const HEART_BEAT_INTERVAL = 2000;

        if (localStorage.getItem(SessionKey._LOGIN_GUID)) {
            sessionStorage.setItem(SessionKey._LOGIN_GUID, localStorage.getItem(SessionKey._LOGIN_GUID));
        }
        setInterval(function () {
            var localGuid = localStorage.getItem(SessionKey._LOGIN_GUID);
            var sessionGuid = sessionStorage.getItem(SessionKey._LOGIN_GUID);

            if (localGuid != sessionGuid && localGuid != null && localGuid != 'null' && sessionGuid != null && sessionGuid != 'null') {
                sessionStorage.setItem(SessionKey._LOGIN_GUID, localGuid);
                window.location.href = WebUrl._URL_MAIN;
            }
        }, HEART_BEAT_INTERVAL);

        // Check pending TNC
        if (localStorage.getItem(LocalStorageKey._PENDING_TNC_AGREEMENT)) {
            this.props.setPendingTNC(true);
        }

        fetch(ApiUrl._API_IS_LOGGED_IN, {
            method: ApiKey._API_GET,
            headers: {
                "Accept": ApiKey._API_APPLICATION_JSON,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            }
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                this.props.updateLoginUser(responseJson[ApiKey._API_DATA_KEY]);
            }
            else {
                this.props.resetLogin();
            }
        });

        Notiflix.Report.Init({
            className: 'notiflix-report',
            width: '320px',
            backgroundColor: '#f8f8f8',
            borderRadius: '25px',
            rtl: false,
            zindex: 4002,
            backOverlay: true,
            backOverlayColor: 'rgba(0,0,0,0.5)',
            useGoogleFont: false,
            fontFamily: 'Quicksand',
            svgSize: '110px',
            plainText: true,
            titleFontSize: '16px',
            titleMaxLength: 34,
            messageFontSize: '13px',
            messageMaxLength: 400,
            buttonFontSize: '14px',
            buttonMaxLength: 34,
            cssAnimation: true,
            cssAnimationDuration: 360,
            cssAnimationStyle: 'fade',

            success: {
                svgColor: '#32c682',
                titleColor: '#1e1e1e',
                messageColor: '#242424',
                buttonBackground: '#32c682',
                buttonColor: '#fff',
                backOverlayColor: 'rgba(50,198,130,0.2)',
            },

            failure: {
                svgColor: '#ff5549',
                titleColor: '#1e1e1e',
                messageColor: '#242424',
                buttonBackground: '#ff5549',
                buttonColor: '#fff',
                backOverlayColor: 'rgba(255,85,73,0.2)',
            },

            warning: {
                svgColor: '#eebf31',
                titleColor: '#1e1e1e',
                messageColor: '#242424',
                buttonBackground: '#eebf31',
                buttonColor: '#fff',
                backOverlayColor: 'rgba(238,191,49,0.2)',
            },

            info: {
                svgColor: '#26c0d3',
                titleColor: '#1e1e1e',
                messageColor: '#242424',
                buttonBackground: '#26c0d3',
                buttonColor: '#fff',
                backOverlayColor: 'rgba(38,192,211,0.2)',
            },
        });

        Notiflix.Confirm.Init({
            position: 'center',
            distance: '200px',
            titleColor: '#000000',
            plainText: false,
            messageMaxLength: 500,
            backgroundColor: '#f8f8f8',
            okButtonColor: '#f8f8f8',
            okButtonBackground: '#32c682',
            cancelButtonColor: '#000000',
            cancelButtonBackground: '#f8f8f8',
        });

        window.addEventListener('storage', createStorageListener(store));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.authState.isForbidden !== this.props.authState.isForbidden && this.props.authState.loginStateInitialized) {
            // window.location.href = WebUrl._URL_DASHBOARD;
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div className="error" style={{ "padding": "20px" }}>
                    <img src={require("./assets/img/error.png")} style={{ "maxWidth": "100px", "objectFit": "contain", "marginLeft": "auto", "marginRight": "auto" }} />
                    <h3>Something went wrong, please contact admin for help</h3>
                    <div className="m-b-30">
                        {window.navigator.userAgent}
                    </div>
                </div>
            );
        }
        else {
            return (
                <PageSettings.Provider value={this.state}>
                    <Helmet>
                        {
                            this.props.authState.isLoggedIn ?
                                <meta name="viewport" content="width=1920, initial-scale=1, shrink-to-fit=yes" /> :
                                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes" />
                        }
                    </Helmet>
                    <LoadingOverlay
                        active={this.props.appState.isBusy || !this.props.authState.loginStateInitialized}
                        spinner
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                zIndex: '10000',
                                position: 'fixed',
                            })
                        }}
                        text={this.props.appState.message}
                    >
                    </LoadingOverlay>
                    {
                        this.props.authState.loginStateInitialized &&
                        <div className={
                            'fade show page-container ' +
                            (this.state.pageTopMenu ? 'page-with-top-menu ' : '') +
                            (this.state.pageContentFullHeight ? 'page-content-full-height ' : '') +
                            (this.state.hasScroll ? 'has-scroll ' : '')
                        }>
                            {this.state.pageHeader && (<Header />)}
                            {this.state.pageContent && (<Content />)}
                            {this.state.pageFooter && (<Footer />)}
                        </div>
                    }
                </PageSettings.Provider>
            )
        }
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(
    mapStateToProps,
    {
        disposeMessage: disposeMessage,
        updateLoginUser: updateLoginUser,
        resetLogin: resetLogin,
        setPendingTNC: setPendingTNC
    }
)(App);