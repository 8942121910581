import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getValidationMessage } from "../../util/Util";
import Select from 'react-select';

/// <summary>
/// Author : CK
/// </summary>
const Input = React.forwardRef((props, ref) => {
    const {
        name,
        type,
        placeholder,
        defaultValue,
        patternErrorTxt,
        errors,
        onChange,
        label,
        options = [],
        className,
        disabled,
        value,
        isSearchable = false,
        onInput,
        onFocus,
        onBlur,
        onInnerButtonClick,
        row,
        buttonClassName = '',
        color = 'success',
        buttonText = 'SUBMIT',
        ...restProps
    } = props;
    const { t } = useTranslation();
    const _INPUT_SELECT_TYPE = 'select';
    var _selectOptions = options.map(option => { return { label: t(option.label), value: option.value } });

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (defaultValue !== undefined) {
            if (onChange) {
                onChange(defaultValue == null ? "" : defaultValue);
            }
        }
    }, [defaultValue]);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (options.length > 0 && options.find(i => i.value == (value != undefined ? value : defaultValue)) == null) {
            if (onChange) {
                onChange(options[0].value);
            }
        }
    }, [options.length]);

    return (
        <div className={`${type != 'hidden' ? `form-group m-b-15 ${row && 'flex-row'}` : ''}`}>
            {label && <label>{label}</label>}
            <div className={`${onInnerButtonClick && 'inline-input-row row'}`}>
                {
                    type == _INPUT_SELECT_TYPE ?
                        <Select
                            placeholder={placeholder}
                            className={`form-select ${className}`}
                            name={name}
                            isSearchable={isSearchable}
                            options={_selectOptions}
                            value={_selectOptions.find(i => i.value == (value != undefined ? value : defaultValue))}
                            onChange={e => onChange(e.value)}
                            isDisabled={disabled} />
                        :
                        <input
                            type={type}
                            className={`form-control form-control-xs ${className}`}
                            ref={ref}
                            name={name}
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            value={value}
                            onFocus={(e) => { onFocus && onFocus(e.target.value) }}
                            onBlur={(e) => { onBlur && onBlur(e.target.value) }}
                            onInput={(e) => { onInput && onInput(e.target.value) }}
                            onChange={(e) => { onChange && onChange(e.target.value) }}
                            disabled={disabled}
                            {...restProps} />
                }
                {
                    onInnerButtonClick &&
                    <button
                        onClick={onInnerButtonClick}
                        className={`btn btn-${color} input-inner-button ${buttonClassName}`}>
                        {t(buttonText)}
                    </button>
                }
                {errors && errors[name] && <div className="invalid-feedback">{t(getValidationMessage(errors[name], patternErrorTxt))}</div>}
            </div>
        </div>
    );
});

export default Input;