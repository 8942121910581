import { AlertTypes } from '../util/Constant.js';
import { showMessage } from '../util/Util';
import i18n from '../localization/i18n';

export const _BUSY = 'busy';
export const _IDLE = 'idle';
export const _API_CALLED = 'setApiCalled';

export const _ALERT_TYPES = {
    SET_ALERT: 'SetAlert',
    NONE: 'AlertNone',
}

/// <summary>
/// Author : -
/// </summary>
export const setBusy = (iconEnabled, message) => {
    return async dispatch => {
        dispatch({
            type: _BUSY,
            message: message,
            iconEnabled: iconEnabled
        });
    }
}

/// <summary>
/// Author : -
/// this function is to only update user status to logout
/// </summary>
export const setIdle = () => {
    return async dispatch => {
        dispatch({
            type: _IDLE
        });
    }
}

/// <summary>
/// Author : -
/// </summary>
export const setPerformApiCall = (status, url) => {
    return async dispatch => {
        dispatch({
            type: _API_CALLED,
            data: { success: status, url: url }
        });
    }
}

/// <summary>
/// Author: -
/// </summary>
export const showResponseMessage = (status, message, translateRequired = true) => {
    return dispatch => {
        var messageToShow = message;
        if (translateRequired) {
            messageToShow = i18n.t(message);
        }
        dispatch(showMessage({ type: (status ? AlertTypes._SUCCESS : AlertTypes._ERROR), content: messageToShow }));
    }
}

/// <summary>
/// Author: -
/// </summary>
export const disposeMessage = () => {
    return dispatch => {
        dispatch({ type: _ALERT_TYPES.NONE });
    }
}