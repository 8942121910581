import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApiKey, ApiUrl, Pool, InputValidationKey, AlertTypes, Status, SettingKey, EatType, BetType, Role } from "../../util/Constant";
import { useTranslation } from 'react-i18next';
import { showMessage, numberWithCurrencyFormat, isObjectEmpty } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import ClipLoader from "react-spinners/ClipLoader";

/// <summary>   
/// Author : CK
/// </summary>
const CreditLimit = props => {
    const { t } = useTranslation();
    const _userData = useSelector(state => state.authState.userData);
    const [userInfo, setUserInfo] = useState();
    const [isLoading, setIsLoading] = useState(false);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (!isObjectEmpty(_userData)) {
        getUserInfo(_userData.userId);
        }
    }, []);

    /// <summary>
    /// Author: CK
    /// </summary>
    async function getUserInfo(userId) {
        setIsLoading(true);
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_DETAIL_BY_ID + "?&userId=" + userId, { headers: { "skipLoading": true } });

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setUserInfo(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            showMessage({
                type: AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY])
            });
        }

        setIsLoading(false);
    }

    return (
        <div className="credit-container">
            <div>
                <p className="balance">
                    {userInfo && userInfo.agent ?
                        `${numberWithCurrencyFormat(userInfo.agent.creditLeft)} / ${numberWithCurrencyFormat(userInfo.agent.creditLimit)}`
                        : `0.00 / 0.00`
                    }     
                </p>
                <p>{t("CREDIT_BALANCE").toUpperCase()} / {t("LIMIT").toUpperCase()}</p>
            </div>
            {
                _userData && !isLoading ?
                    <button className="btn" onClick={() => { getUserInfo(_userData.userId) }}>
                        <div className="transparent-container" />
                        <i className="fas fa-redo"></i>
                    </button>
                    : <div className="m-l-15"><ClipLoader size={20} color={"#fff"} /></div>
            }
        </div>
    );
}

export default CreditLimit;