import { _SET_CHANGED_PACKAGE, _REMOVE_CHANGED_PACKAGE, _SET_REQUIRED_REFRESH } from './PackageAction';

const _INITIAL_STATE = {
    changedPackages: [],
    requiredRefresh: false
};

/// <summary>
/// Author : CK
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _SET_CHANGED_PACKAGE:
            let updatedChangedPackage = state.changedPackages;
  
            if (updatedChangedPackage.length > 0 && action.data) {
                updatedChangedPackage = updatedChangedPackage.filter(i => !(i['id'] == action.data['id'] && i['targetUserId'] == action.data['targetUserId']));
            }

            updatedChangedPackage = [...updatedChangedPackage, action.data];

            return {
                ...state,
                changedPackages: updatedChangedPackage
            }
        case _REMOVE_CHANGED_PACKAGE:
            return {
                ...state,
                changedPackages: state.changedPackages.filter(i => i['targetUserId'] != action.targetUserId)
            }
        case _SET_REQUIRED_REFRESH:
            return {
                ...state,
                requiredRefresh: action.data
            }
        default:
            return state;
    }
};