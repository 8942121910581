export const _SET_CHANGED_PACKAGE = 'SetChangedPackage';
export const _REMOVE_CHANGED_PACKAGE = 'RemoveChangedPackage';
export const _SET_REQUIRED_REFRESH = 'SetRequiredRefresh';

/// <summary>
/// Author : CK
/// </summary>
export const setPackageChanged = (data) => {
    return async dispatch => {
        dispatch({
            type: _SET_CHANGED_PACKAGE,
            data: data
        });
    }
}

/// <summary>
/// Author : CK
/// </summary>
export const removePackageChanged = (targetUserId) => {
    return async dispatch => {
        dispatch({
            type: _REMOVE_CHANGED_PACKAGE,
            targetUserId: targetUserId
        });
    }
}

/// <summary>
/// Author : Wind
/// </summary>
export const setRequiredRefresh = (data) => {
    return async dispatch => {
        dispatch({
            type: _SET_REQUIRED_REFRESH,
            data: data
        });
    }
}

/// <summary>
/// Author : Wind
/// </summary>
export const disableRefresh = (data) => {
    return async dispatch => {
        dispatch({
            type: _SET_REQUIRED_REFRESH,
            data: data
        });
    }
}
