import store from "../ApplicationStore.js";
import { AccessRight, Status } from "./Constant";
import { stringIsNullOrEmpty, isObjectEmpty } from "./Util.js";

//variable here will be long live throughout the process, this is intented
var _permissionArrKey = [];

/// <summary>
/// Author : CK
/// Edited : Wind
/// </summary>
export function checkIfPermissionExist(permissionString, permissionAccessLevel, targetDownline = '') {
    var hasPermission = false;
    
    if (_permissionArrKey.length == 0 && store.getState()["authState"]["userData"]["accessString"] !== undefined) {
        var userPermissionData = store.getState()["authState"]["userData"]["accessString"].split(";");
        var specificPermissionCheck = userPermissionData.find(i => i.includes(permissionString));

        if (specificPermissionCheck) {
            var keyValuePermission = specificPermissionCheck.split(":");
            var accessLevels = permissionAccessLevel.split("") 
            
            if (!stringIsNullOrEmpty(targetDownline) && !isObjectEmpty(store.getState()["authState"]["userData"]["targetLineAccessRight"])) {
                hasPermission = store.getState()["authState"]["userData"]["targetLineAccessRight"].hasOwnProperty(targetDownline);
            }
            else {
                hasPermission = accessLevels.some(i => keyValuePermission[1].toString().includes(i));
            }
        }
    }

    return hasPermission;
}

/// <summary>
/// Author : -
/// this expected to be call on logout so that next login can fetch new data
/// </summary>
export function clearPermissionData() {
    _permissionArrKey = [];
}